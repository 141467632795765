import { FC, useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { CbDateRangePicker, DateRange } from '../CbDateRangePicker';
import { CbSelect, CbSelectOption } from '../CbSelect';
import Calendar from '../Icons/Calendar';
import Close from '../Icons/Close';
import FilterOutline from '../Icons/FilterOutline';
export interface CbMobileFilterDateRangeProps {
  contentBackgroundColor?: string;
  colorScheme?: string;
  dateFormat?: string;
  defaultFilterValue?: CbSelectOption;
  filterOptions?: CbSelectOption[];
  hasFilter?: boolean;
  hasDateRange?: boolean;
  iconColor?: string;
  maxDate?: Date;
  selectedDateRange?: DateRange;
  onChange?: (date: DateRange) => void;
  onFilterChange?: (value: string) => void;
}
export const CbMobileFilterDateRange: FC<CbMobileFilterDateRangeProps> = ({
  colorScheme = 'whiteAlpha',
  dateFormat,
  defaultFilterValue,
  filterOptions,
  hasFilter = true,
  hasDateRange = true,
  iconColor = 'white',
  maxDate,
  selectedDateRange,
  onChange,
  onFilterChange = () => {}
}) => {
  const [showDate, setShowDate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const handleDateClick = (): void => {
    setShowDate(!showDate);
    setShowFilter(false);
  };
  const handleFilterClick = (): void => {
    setShowFilter(!showFilter);
    setShowDate(false);
  };
  return <Box width="100%" data-sentry-element="Box" data-sentry-component="CbMobileFilterDateRange" data-sentry-source-file="CbMobileFilterDateRange.tsx">
      <Flex justifyContent="flex-end" data-sentry-element="Flex" data-sentry-source-file="CbMobileFilterDateRange.tsx">
        {hasFilter && <IconButton _focus={{
        outline: 'none'
      }} borderRadius={100} borderWidth="1px" borderColor={iconColor} colorScheme={showFilter ? 'gray' : colorScheme} aria-label="Toggle Filer" onClick={handleFilterClick}>
            {showFilter ? <Close /> : <FilterOutline />}
          </IconButton>}
        {hasDateRange && <IconButton _focus={{
        outline: 'none'
      }} borderRadius={100} borderWidth="1px" borderColor={iconColor} colorScheme={showDate ? 'gray' : colorScheme} aria-label="Toggle Date Picker" marginLeft={2} onClick={handleDateClick}>
            {showDate ? <Close /> : <Calendar />}
          </IconButton>}
      </Flex>
      <Box display={showDate ? 'block' : 'none'} marginTop={3} paddingRight="44px" data-sentry-element="Box" data-sentry-source-file="CbMobileFilterDateRange.tsx">
        <CbDateRangePicker color={iconColor} borderColor={iconColor} dateFormat={dateFormat} iconColor={iconColor} maxDate={maxDate} variant="cb-outlineWhite" width="100%" _focus={{
        borderColor: 'white'
      }} onChange={onChange} values={selectedDateRange} data-sentry-element="CbDateRangePicker" data-sentry-source-file="CbMobileFilterDateRange.tsx" />
      </Box>
      {filterOptions && showFilter && <Box marginTop={3}>
          <CbSelect borderColor={iconColor} color={iconColor} getValueFromOption={filter => filter.type} onChange={onFilterChange} options={filterOptions} value={defaultFilterValue} variant="cb-input-outlineWhite" width="100%" />
        </Box>}
    </Box>;
};