import { FC } from 'react';
import { Box, Center, CenterProps, Text } from '@chakra-ui/react';
export interface CbEmptySearchStateProps extends CenterProps {
  alert?: string;
  suggestion?: string;
}
export const CbEmptySearchState: FC<CbEmptySearchStateProps> = ({
  alert,
  suggestion,
  ...props
}) => {
  return <Center backgroundColor="cloud.300" borderRadius={16} color="cloud.700" {...props} data-sentry-element="Center" data-sentry-component="CbEmptySearchState" data-sentry-source-file="CbEmptySearchState.tsx">
      <Box textAlign="center" data-sentry-element="Box" data-sentry-source-file="CbEmptySearchState.tsx">
        <Text fontWeight="bold" fontSize={{
        base: 'xl',
        md: '2xl'
      }} data-sentry-element="Text" data-sentry-source-file="CbEmptySearchState.tsx">
          {alert}
        </Text>
        <Text marginTop={2} fontWeight="bold" fontSize={{
        base: 'xl',
        md: '2xl'
      }} data-sentry-element="Text" data-sentry-source-file="CbEmptySearchState.tsx">
          {suggestion}
        </Text>
      </Box>
    </Center>;
};