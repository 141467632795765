import { FC } from 'react';
import { Flex, Text, Skeleton } from '@chakra-ui/react';
import RaffleTicket from 'ui/assets/RaffleTicket';
import { formatNumber } from '../../helpers/numbers';
export interface CbRaffleTicketBalanceProps {
  color?: string;
  raffleTicketBalance?: number;
  fontWeight?: string;
  isPending: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}
export const CbRaffleTicketBalance: FC<CbRaffleTicketBalanceProps> = ({
  color = 'text.secondary',
  raffleTicketBalance,
  fontWeight = 'semibold',
  isPending,
  size = 'md'
}) => {
  const tokenSize = size === 'sm' ? ['16px', '20px'] : ['20px', '24px'];
  return <Flex alignItems="center" data-sentry-element="Flex" data-sentry-component="CbRaffleTicketBalance" data-sentry-source-file="CbRaffleTicketBalance.tsx">
      <RaffleTicket marginRight={1} width={tokenSize} height={tokenSize} data-sentry-element="RaffleTicket" data-sentry-source-file="CbRaffleTicketBalance.tsx" />
      {isPending ? <Skeleton data-testid="skeleton" height={6} width={7} /> : <>
          {raffleTicketBalance && <Text color={color} fontSize={size} fontWeight={fontWeight}>
              {formatNumber(raffleTicketBalance)}
            </Text>}
        </>}
    </Flex>;
};