import { Flex, Text } from '@chakra-ui/react';
export const PlayerCount = ({
  playerCount
}) => {
  return <Flex marginBottom={{
    base: 4,
    md: 'auto'
  }} border="1px solid" direction="column" alignItems="center" justifyContent={'space-between'} boxShadow="0 0 5px 5px #44D0EF" borderRadius={16} marginRight={4} padding={4} textAlign="center" width="100%" data-sentry-element="Flex" data-sentry-component="PlayerCount" data-sentry-source-file="PlayerCount.tsx">
      <Text color="cloud.500" fontSize={{
      base: 'xs'
    }} marginBottom={{
      base: 2
    }} data-sentry-element="Text" data-sentry-source-file="PlayerCount.tsx">
        {'Player Count'}
      </Text>
      <Text color="white" fontSize={{
      base: 'lg',
      md: 'xl'
    }} fontFamily="codenext" lineHeight="shorter" data-sentry-element="Text" data-sentry-source-file="PlayerCount.tsx">
        {playerCount || '-'}
      </Text>
    </Flex>;
};