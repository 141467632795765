import { Flex, Text } from '@chakra-ui/react';
export const Winner = ({
  userName
}) => <Flex marginBottom={{
  base: 4,
  md: 'auto'
}} border="1px solid" direction="column" alignItems="center" justifyContent={'space-between'} borderColor="cloud.800" borderRadius={16} marginLeft={{
  md: 4
}} padding={4} textAlign="center" width="100%" data-sentry-element="Flex" data-sentry-component="Winner" data-sentry-source-file="Winner.tsx">
    <Text color="cloud.500" fontSize={{
    base: 'xs'
  }} marginBottom={{
    base: 2
  }} data-sentry-element="Text" data-sentry-source-file="Winner.tsx">
      {'1st Place'}
    </Text>
    <Text color="white" fontSize={{
    base: 'sm',
    md: 'md'
  }} fontFamily="codenext" lineHeight="shorter" noOfLines={{
    base: 2,
    md: 1
  }} data-sentry-element="Text" data-sentry-source-file="Winner.tsx">
      {userName || '-'}
    </Text>
  </Flex>;