import { Flex, Text } from '@chakra-ui/react';
export const RewardCount = ({
  rewardsAwardedCount
}) => {
  return <Flex marginBottom={{
    base: 4,
    md: 'auto'
  }} border="1px solid" direction="column" alignItems="center" justifyContent={'space-between'} borderColor="cloud.800" borderRadius={16} padding={4} textAlign="center" width="100%" data-sentry-element="Flex" data-sentry-component="RewardCount" data-sentry-source-file="RewardCount.tsx">
      <Text color="cloud.500" fontSize={{
      base: 'xs'
    }} marginBottom={{
      base: 2
    }} noOfLines={1} data-sentry-element="Text" data-sentry-source-file="RewardCount.tsx">
        {'Prizes Won'}
      </Text>
      <Text color="white" fontSize={{
      base: 'lg',
      md: 'xl'
    }} fontFamily="codenext" lineHeight="shorter" data-sentry-element="Text" data-sentry-source-file="RewardCount.tsx">
        {rewardsAwardedCount || '-'}
      </Text>
    </Flex>;
};